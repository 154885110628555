// 保存当前菜单栏的路径
export const savePath = (state, pathName) => {
    state.pathName = pathName;
};

// 保存当前点击的数据源
export const saveCurrDbSource = (state, currDbSource) => {
    state.currDbSource = currDbSource;
};

// 保存登陆时的token
export const saveLoginToken = (state, token) => {
    state.token = token;
};

// 保存创建账号时的P_id
export const saveCreateP_id = (state, p_id) => {
    state.p_id = p_id;
    sessionStorage.setItem('p_id', p_id);
};

// 保存创建账号时的P_id
export const saveUpdateId = (state, id) => {
    state.id = id;
    sessionStorage.setItem('id', id);
};

// 保存创建账号时的franchisee_id
export const saveFranchiseeId = (state, franchisee_id) => {
    state.franchisee_id = franchisee_id;
};

// 保存当前账号的数据accountForm
export const saveAccountForm = (state, accountForm) => {
    state.accountForm = accountForm;
};
//保存当前店铺的数据
export const saveStoreForm = (state, storeForm) => {
    state.storeForm = storeForm;
};
// 判断跳转的是否是创建页面isStoreCreate
export const saveIsStoreCreate = (state, isStoreCreate) => {
    state.isStoreCreate = isStoreCreate;
};
// 判断跳转的是否是创建页面isAccountCreate
export const saveIsAccountCreate = (state, isAccountCreate) => {
    state.isAccountCreate = isAccountCreate;
};

// 判断跳转的是否是创建页面isAccountCreate
export const saveCloseTagFlag = (state, isCloseTagFlag) => {
    state.isCloseTagFlag = isCloseTagFlag;
};

// 保存合伙人列表点击编辑传入的合伙人id对应的数据
export const savePartnerContent = (state, partnerContent) => {
    state.partnerContent = partnerContent;
};

// 保存移动端首页列表点击区域合伙人列表,保存移动首页列表项的id
export const saveMobile_home_id = (state, appletConfigItemID) => {
    state.appletConfigItemID = appletConfigItemID;
};
// 保存移动端首页列表点击区域合伙人列表,保存移动首页列表项的标题
export const saveMobile_home_title = (state, mobileAddress) => {
    state.mobileAddress = mobileAddress;
};

// 保存移动端图片列表点击编辑,保存编辑项的内容
export const saveAppletContent = (state, appletPictsItem) => {
    state.appletPictsItem = appletPictsItem;
};

// 判断跳转的是否是创建页面isAccountCreate
// export const saveCommonPid = (state, commonPid) => {
//     state.commonPid = commonPid
// }

// 判断跳转的是否是创建页面isAccountCreate
// export const saveCommonId = (state, commonId) => {
//     state.commonId = commonId
// }

// 小程序筛选管理
export const appleScreenType = (state, payload) => {
    state.typeIdScreen = payload.typeId;
    state.addressIdScreen = payload.addressId;
    state.typeNameScreen = payload.typeName;
    state.address = payload.address;
}
// 小程序筛选管理详情
export const detailsManage = (state, payload) => {
    state.typeNameManage = payload.name;
    state.search_tabbar_idManage = payload.search_tabbar_id;
}
// 小程序筛选配置详情
export const detailsConfiguration = (state, payload) => {
    state.search_detail_idConfiguration = payload.id;
    state.typeNameConfiguration = payload.name;
    state.sub_titleConfiguration = payload.sub_title;
    state.tabbar_typeConfiguration = payload.tabbar_type;
}
export const saveCreateDetailsId = (state, question_id) => {
    state.question_id = question_id
}
