export default {
    // pathName: '', // 路由
    // currDbSource: {}, // 当前数据源
    // currJobData: {}, // 当前元数据
    // DbSource: [], // 所有数据源，供元数据界面下拉列表使用
    // selectJobMeta: {}, // 当前选择的元数据（搜索后点击的单条数据）
    // specialSubject: [], // 专题数据（多条）
    // duplicateJobMeta: {}, // 复制的数据
    token: '',
    // 创建账号时的p_id
    p_id: 0,
    commonId: sessionStorage.getItem('commonId'),
    commonPid: sessionStorage.getItem('commonPid'),
    // 查看子账户传入的上级账号franchisee_id
    franchisee_id: null,
    // 当前账户数据
    accountForm: {},
    // 当前店铺数据、
    storeForm: {},
    //是否是创建页面
    isStoreCreate: true,
    isAccountCreate: true,
    isCloseTagFlag: false,
    // 合伙人列表点击编辑存入的id对应的数据
    partnerContent: {},
    appletConfigItemID: 0, //
    mobileAddress: '', //移动首页的列表项的省市区
    appletPictsItem: {}, //保存移动端图片列表点击编辑的那一项

    // 小程序筛选管理
    typeIdScreen: null,
    addressScreenId: null,
    typeNameScreen: null,

    // 小程序筛选详情
    typeNameManage: null,
    search_tabbar_idManage: null,
    
    // 小程序筛选配置详情
    typeNameConfiguration: null,
    search_detail_idConfiguration: null,
    tabbar_typeConfiguration:null,
    question_id:null,

  };
